@import 'emoji-mart/css/emoji-mart.css';
.chat-wrapper {
  width: 100%;
  height: 30rem;
  margin-top: 0;
  padding: 0.875rem 1.5rem 1.063rem 1.375rem;
  border-radius: 0.313rem;
  border: solid 0.063rem #e5e5e5;
}

.chat-frame {
  top: 0.938rem;
  left: 0;
  padding-left: 0;
}
.Chat {
  width: 100%;
  height: 72vh;
  border: 0.063rem solid #e5e5e5;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
}
.disable-chat-frame {
  pointer-events: none;
  opacity: 0.5;
}
.chat-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 1rem;
  color: #686868;
}
.timer__stop__chat {
  height: 72vh !important;
}
.user-chat-heading {
  height: 2.938rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #686868;
  padding: 0.813rem 0 0 1.513rem;
}
.chat-heading-content {
  height: auto;
  font-size: 0.813rem;
  font-weight: 500;
  color: #3f3f3f;
}
.Chat .MessageList {
  box-sizing: border-box;
  flex: 1 1;
  margin: 0.625rem;
}
.MessageList {
  overflow-y: auto;
  padding: 0.25rem;
  height: 19rem;
  &::-webkit-scrollbar {
    display: none;
  }
}
.Message {
  margin: 0.625rem 0;
  color: #3f3f3f;
}
.Message,
.Message.me .author {
  font-size: 0.875rem;
}
.Message .author,
.teacher-author {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
}
.message-layout {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: left;
}
.Message.me {
  &:hover {
    .message-body {
      background-color: #f9f9f9;
      .edit-icon {
        float: right;
        display: block;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.author-circle {
  border-radius: 50%;
  background-color: #dfba60;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  &::after {
    content: '';
    padding-bottom: 100%;
  }
}

.message-body {
  margin-left: 0.625rem;
  width: 89%;
}
.Chat .edit-form,
.Chat .MessageForm {
  margin: 1.25rem;
  box-sizing: border-box;
  flex: 0 0 2.188rem;
  height: 2.875rem;
  padding: 0.313rem 0.375rem 0.313rem 0.5rem;
  border: 0.063rem solid #e5e5e5;
}
.edit-form,
.MessageForm {
  position: relative;
  display: flex;
  border: 0;
}
.edit-form .input-container,
.MessageForm .input-container {
  flex: 1 1;
  margin: 0.063rem;
}
.edit-form .button-container,
.MessageForm .button-container {
  flex: 0 0 0 0;
  margin: 0;
}
.edit-form .chat-submit-btn,
.MessageForm .chat-submit-btn {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fefefe;
  width: 2.75rem;
  border-radius: 0.313rem;
  background-color: #f36b7f;
  border: 0;
}
.edit-form .chat-submit-btn,
.edit-form input,
.MessageForm .chat-submit-btn,
.MessageForm input {
  font-size: inherit;
  height: 100%;
  outline: none;
}
.MessageForm input {
  width: 100%;
  border: none;
  background-color: #ffffff;
  padding: 0 0 0 0.625rem;
}
.emoji-btn {
  float: right;
  border: none;
  margin: 0 0.625rem;
  cursor: pointer;
  font-size: 1.5rem;
}
.send-msg {
  margin: 0 0 0.125rem 0.625rem;
}
.chat-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 44vh;

  img {
    width: 6.25rem;
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
  }
}

.message-content {
  display: inline;
}
.Message.log {
  color: #bbb;
}

.user-message {
  overflow-wrap: anywhere;
}

.edit-icon {
  display: none;
  margin: 0.188rem;
}

.transparency {
  opacity: 0.4;
}
.MessageForm .input-container {
  flex: 1;
  margin: 0.063rem;
}

.MessageForm input {
  &::placeholder {
    font-size: 0.938;
    color: #c2c2c2;
  }
  width: 100%;
  border-radius: 0.313rem;
  border: solid 0.063rem #c4ccc4;
  background-color: #ffffff;
  padding: 0 0 0 0.625rem;
}

.user-chat-heading {
  height: 2.938rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #686868;
  padding: 0.813rem 0 0 1.438rem;
}
.edit-form {
  @extend .MessageForm;
  flex: 0;
  margin: 0 !important;
  padding: 0 !important;
  height: 2.5rem;
  input {
    width: 98%;
  }
}
.Message {
  margin: 0.625rem 0;
  a {
    color: #4285f4;
  }
}

.chat-user-name {
  margin: auto 1.875rem;
  background-color: transparent;
  p {
    font-size: 1rem;
    color: #686868;
  }
  label {
    display: block;
    font-size: 0.875rem;
    font-weight: 100;
    color: #686868;
  }
  input {
    width: 100%;
    border: #ebebeb 0.063rem solid;
    outline: none;
    border-radius: 0.313rem;
    height: 2rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    color: #686868;
  }
}
.chat-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 1rem;
  color: #686868;
}
.emoji-btn {
  float: right;
  border: none;
  margin: 0 0.625rem;
  cursor: pointer;
  font-size: 1.5rem;
}
.emoji-picker {
  position: absolute;
  outline: none;
  right: 8%;
  float: right;
  margin-left: 12.5rem;
}

.Chat .emoji-picker {
  @extend .emoji-picker;
  top: 0;
}
.emoji-mart-search {
  height: 1.875rem;
}
